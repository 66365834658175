import Moment from 'moment'
export default {
	data() {
		return {
			queryForm: {
				category: '',
				channel: '',
				rule: '',
				date: [
					Moment().startOf('day').format('x'),
					Moment().endOf('day').format('x')
				],
				spu: ''
			},
			q: {
				channel: '',
				createdAtMin: Moment().startOf('day').format('x'),
				createdAtMax: Moment().endOf('day').format('x')
			},
			tableData: [],
			fetchOnCreated: false
		}
	},
	created() {
		const {start, end} = this.$route.query
		if (start && end && /\d+/.test(start) && /\d+/.test(end) && Number(start) < Number(end)) {
			this.queryForm.date = [start, end]
			this.q.createdAtMin = start
			this.q.createdAtMax = end
		}
	},
	methods: {
		searchEvent() {
			this.q = {
				createdAtMin: this.queryForm.date[0],
				createdAtMax: this.queryForm.date[1],
				channel: this.queryForm.channel,
				spus: this.queryForm.spu.match(/[\w-]+/g),
				category: this.queryForm.category,
				rule: this.queryForm.rule
			}
			const search = (this.tab ? `tab=${this.tab}&` : '') + `start=${this.queryForm.date[0]}&end=${this.queryForm.date[1]}`
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.page.current = 1
			this.getList()
		},
		handleList(content) {
			this.tableData = content.map(v => {
				let originPic = undefined
				if (v.picManual) {
					originPic = false
				} else if (v.picAuto) {
					originPic = true
				}
				return {
					...v,
					originPic
				}
			})
		}
	}
}