<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;getList()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="exportData">下 载</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item-categories v-model="queryForm.category" :site="queryForm.channel"></form-item-categories>
					<form-item label="选款规则:" style="width:230px;">
						<el-select v-model="queryForm.rule" placeholder="请选择" size="mini" clearable>
							<el-option v-for="item in recommendRules" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="商品id:" style="width:200px;">
						<el-input v-model="queryForm.spu" placeholder="请输入" size="mini"></el-input>
					</form-item>
					<form-item label="选择日期:" style="width:400px;">
						<el-date-picker v-model="queryForm.date" type="datetimerange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="timestamp" style="width:340px;" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div class="d-flex flex-row flex-wrap">
				
			</div>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<non-distincted-table :tableData="tableData" @handleSelectionChange="handleSelectionChange" @sortChange="sortChange" class="mt-3 w-100" :readonly="true"></non-distincted-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import NonDistinctedTable from '@/components/carousels/non-distincted-table.vue'
	import Config from './mixins/config.js'
	import Lodash from 'lodash'
	import Data from './mixins/data.js'
	import {
		mapState,
		mapGetters
	} from 'vuex'
	export default {
		mixins: [Page, Common, Config, Data],
		inject: ['adminLayout'],
		components: {
			NonDistinctedTable
		},
		data() {
			return {
				orderBy: 'tps.sales_total desc',
				namespace: 'fb-carousel-ads'
			}
		},
		computed: {
			...mapGetters({
				recommendRules: 'fb-carousel-ads/recommendRules'
			})
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q,
						orderBy: this.orderBy
					},
					trigger
				}
				return this.$store.dispatch('fb-carousel-ads/getList', payload)
			},
			// Table Events
			sortChange(column) {
				const {prop, order} = column
				if (order === 'ascending') {
					this.orderBy = `${Lodash.snakeCase(prop)} asc`.replace(/^tps_/, 'tps.')
				} else if (order === 'descending') {
					this.orderBy = `${Lodash.snakeCase(prop)} desc`.replace(/^tps_/, 'tps.')
				} else {
					this.orderBy = ''
				}
				this.page.current = 1
				this.getList()
			},
			exportData() {
				if (JSON.stringify(this.q) === '{}') return
				const createdAtMin = Moment(Number(this.queryForm.date[0])).format('YYYY-MM-DD HH:mm:ss')
				const createdAtMax = Moment(Number(this.queryForm.date[1])).format('YYYY-MM-DD HH:mm:ss')
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/getList`,
					payload: {...this.q, orderBy: this.orderBy},
					title: `选款报表(${createdAtMin}~${createdAtMax})`,
					pagable: {page: 1, size: 50},
					dispatch: this.$store.dispatch,
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {...lhs, ...rhs}
						}, {})
						return {params}
					}
				})
			}
		}
	}
</script>
